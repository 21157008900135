<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg" style="width:100%">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">畅游和歌山必备的景点套票，精选和歌山多个人气设施与美食店家，一週内可以任选三个喜欢的设施，出示电子票券QR code就可以轻松入场体验。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●同设施只可以使用一次，相同设施不可再入场<br>
          ●票劵使用效期为购买日起<span>90天内</span><br>
           EX：<span>7/1</span>购买票券⇒<span>票期:7/1~9/28</span><br>
          ●票券有效期限: 使用第一个设施后，7日内须使用完毕<br>
          EX：<span>7/1</span>开始使用，<span>有效期限至7/7</span><br>
          ●有效期间内可任<span>选3项设施</span><br>
          ●注意: 单次购买複数票劵时，当其中一张票劵开始使用，所有票劵也将同步开通 。(<span>使用效期7天</span>)<br>
          ●票券开通后，7天的使用效期无法超过票券<span>90天</span>的有效期间。<br>
           EX：票券期限<span>7/1~9/28</span>，若<span>9/27</span>开始使用，其使用效期为<span>9/27~9/28</span><br>
          ●无使用完毕不能兑现<br>
          ●请事先于以下连结确认各设施注意事项・营业时间・公休日情报
        </div>
      </div>
      <!-- 景点介绍 --> 
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事项'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兑换地点' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]" target="_blank" :href="cItem" v-show="msg.title==='官方网站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfwakayama/zh/havefun_title_sc.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hfwakayama/01_sc.png'),
          title: ['NO PHONE NO LIFE之旅~秘密和歌山观光~'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '和歌山县和歌山市美园町5丁目12-2 wakachika 广场 (观光交流中心)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.wakayamakanko.com/wakayama_asobi/npnl/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['包含内容：智慧手机封印袋、透明文件夹、白色地图、专用背包、旅行纪念品'] },
                { cid: 2, text: ['体验时间：9:00～17:00 *不需预约'] },
                { cid: 3, text: ['请事先查看官方网站以确认营业时间。','<br>※公休日：12月29日~1月3日'] },
                ]
            }
          ]
        },
        {
          id: 2,
          img: require('@/assets/images/hfwakayama/02_sc.png'),
          title: ['和歌山拉麵+饺子 or 素食拉麵'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '和歌山县和歌山市美园町5-7-12'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.instagram.com/menyahishio_jrwakayamaekimae/?igshid=NTc4MTIwNjQ2YQ%3D%3D'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间和休息日可能会有变更，请在光顾店铺之前与店铺确认。','<br>※公休日：12月29日~1月3日'] },
                { cid: 2, text: ['素食拉麵不附饺子。'] },
                { cid: 3, text: ['有可能会因为店家客满而无法使用的状况，请见谅'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfwakayama/03_sc.png'),
          title: ['纪州漆器传统产业会馆「URUWASHI馆」馆内1000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒642-0001 和歌山县海南市船尾222番地'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.kishusikki.com/cn/index.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['请以现金支付额外费用。'] },
                { cid: 2, text: ['请在光临前提前查看官方网站以确认营业时间。'] },
                { cid: 3, text: ['如果您想体验漆器莳绘，请提前通过电话进行申请。'] },
                { cid: 4, text: ['漆器莳绘体验的费用因尺寸而异。'] },
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfwakayama/04_sc.png'),
          title: ['纪三井寺特别参观券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒641-0012　和歌山县和歌山市纪三井寺1201'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.kimiidera.com/worship/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['包含内容：参拜费、佛殿观景迴廊入场费、大光明殿参拜费、往返缆车费用。'] },
                { cid: 2, text: ['祈祷、回向、参拜费用以及参拜时间各有不同。请至官网确认。'] },
                { cid: 3, text: ['大光明殿有不开放的日子。'] },
                { cid: 4, text: ['请事前至官网确认最新的营业状况。'] },
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfwakayama/05_sc.png'),
          title: ['海南市物产观光中心「KAIBUTSU-KUN」1,000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '和歌山县海南市名高51-2 (JR海南站内)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.kainankanko.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['若有价差需现场支付差额。'] },
                { cid: 2, text: ['请事前至官网确认营业状况。'] },
                { cid: 3, text: ['无须兑换成实体券，请在结帐时出示电子票。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfwakayama/06_sc.png'),
          title: ['和歌山格兰比亚大酒店 大厅咖啡厅 PASSWORD 「蛋糕套餐」'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '和歌山县和歌山市友田町5丁目18番地'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.jrwesthotels.com/zh/hotels/wakayama/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['包含内容：蛋糕、饮料。'] },
                { cid: 2, text: ['若有价差需现场支付差额。'] },
                { cid: 3, text: ['营业时间请事先至官网确认。','<br>※可能因临时休业・客满而无法使用。'] },
                { cid: 4, text: ['产品图片仅供参考。'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfwakayama/07_sc.png'),
          title: ['自行车租借・E-bike一日使用 1,000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '和歌山县东牟娄郡那智胜浦町筑地 6-1-4 (那智胜浦观光案内所)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://nachikan.jp/activity/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['费用根据使用时间和自行车类型而异。'] },
                { cid: 3, text: ['无须预约。由于车辆数量有限，如果您想确保可使用，请提前通过电子邮件联繫「travel@nachikan.jp」。'] },
                { cid: 4, text: ['营业时间请事先至官网确认。'] },
                { cid: 5, text: ['若有价差需现场支付差额。'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfwakayama/08_sc.png'),
          title: ['平安服装穿着体验1,000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '和歌山县东牟娄郡那智胜浦町筑地 6-1-4 那智胜浦观光案内所<br>和歌山县东牟娄郡那智胜浦町野野 3034-2 大門坂观光案内所'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://nachikan.jp/activity/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。'] },
                { cid: 2, text: ['若有价差需现场支付差额。 (只接受现金)'] },
                { cid: 3, text: ['大门坂茶屋没有投币式储物柜。仅提供带有编号的黑色包包让客人存放衣物。无法保管贵重物品，敬请谅解。'] },
                { cid: 4, text: ['营业时间请事先至官网确认。'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfwakayama/09_sc.png'),
          title: ['「川柳」可选餐券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '那智勝浦町築地4-3-27'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://nachikan.jp/restaurant/1580/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['「秋刀鱼寿司(附汤)・猪排丼(附汤)」择一，或加500日圆可选名产鲔鱼丼(附汤)。※仅收现金'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 10,
          img: require('@/assets/images/hfwakayama/10_sc.png'),
          title: ['「Nigiwai-Ichiba」特製午餐兑换券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '和歌山县东牟娄郡那智胜浦町筑地7-12(店名:Maguro No Wakiguchi)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://nigiwaiichiba.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['请事先查看官方网站以确认营业时间。<br>※公休日：週二'] },
                { cid: 2, text: ['最后点餐为15:30'] },
                { cid: 3, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 11,
          img: require('@/assets/images/hfwakayama/11_sc.png'),
          title: ['纪之松岛游览船'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '和歌山县东牟娄郡那智胜浦町胜浦442-20(纪之松岛旅游接待处)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['http://kinomatsushima.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['请提前在网站上查看运行情况和时间表。<br>※休息日：週三（但是，暴风雨或淡季期间可能会临时关闭）'] },
                { cid: 2, text: ['有可能会因为客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfwakayama/12_sc.png'),
          title: ['动物游乐园 冒险大世界 1日入场劵1000日元优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒649-2201 和歌山県西牟娄郡白滨町坚田2399番地 (售票窗口)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.aws-s.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。(只限入场劵)'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfwakayama/13_sc.png'),
          title: ['SHIRAHAMA KEY TERRACE Hotel Seamore「一日泡汤券」'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒649-2211 和歌山县西牟娄郡白滨町 1821 (柜台)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.keyterrace.co.jp/en/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。'] },
                { cid: 2, text: ['有可能会因为客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 14,
          img: require('@/assets/images/hfwakayama/14_sc.png'),
          title: ['白滨海中展望塔(Coral Princess) 入场券 '],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒649-2211 和歌山县西牟娄郡白滨町 1821 (售票处)'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.keyterrace.co.jp/en/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['请事先查看官方网站以确认营业时间。<br>※天气不佳时可能会临时休业。'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFWakayama&l=sc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-wakayama-1-week-free-pass/sc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>